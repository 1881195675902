import loadable from '@loadable/component';
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Description, NoIndex, title } from '@components/layout/SEO';
import { Loading } from '@components/loading';

const CreditCardForm = loadable(() => import('@features/order/CreditCardForm'));

const CardPage = () => (
  <Layout>
    <Helmet>
      <title>お支払い情報の入力 | {title}</title>
      {Description}
      {NoIndex}
    </Helmet>
    <Suspense fallback={<Loading />}>
      <CreditCardForm />
    </Suspense>
  </Layout>
);

export default CardPage;
